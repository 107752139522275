import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { AppSettings } from 'src/app/app-settings';
import { CommunicationManager } from 'src/app/core/communication-manager';
import { enumTabelaStatusOrcamento } from 'src/app/enum/enumTabelaStatusOrcamento';
import { AdicionarPagamentoPdvModel } from 'src/app/models/cliente/FormaPagamento/AdicionarPagamentoPdvModel';
import { FormaPagamentoModel } from 'src/app/models/cliente/FormaPagamento/formaPagamentoModel';
import { FiltroPesquisaOrcamentoModel } from 'src/app/models/cliente/Orcamento/FiltroPesquisaOrcamentoModel';
import { OrcamentoModel } from 'src/app/models/cliente/Orcamento/orcamentoModel';
import { SituacaoClienteModel } from 'src/app/models/cliente/Orcamento/SituacaoClienteModel';
import { OrcamentoProdutoModel } from 'src/app/models/cliente/OrcamentoProduto/orcamentoProdutoModel';
import { OrcamentoSimulacaoPagamentoModel } from 'src/app/models/cliente/OrcamentoSimulacaoPagamento/orcamentoSimulacaoPagamentoModel';
import { ValoresOrcamentoSimulacaoPagamentoModel } from 'src/app/models/cliente/OrcamentoSimulacaoPagamento/ValoresOrcamentoSimulacaoPagamentoModel';
import { AuthService } from 'src/app/theme/shared/auth/auth.service';
import { AppMensagemService } from 'src/app/theme/shared/components/app-mensagem/app-mensagem.service';

@Injectable({
  providedIn: 'root'
})
export class ApiOrcamentoService {

  private gc: CommunicationManager;
  private api = AppSettings.API.Pdv;
  private version = 'api/'
  private controller = 'orcamento';

  constructor(
    private readonly http: HttpClient,
    private readonly auth: AuthService,
    private readonly mensagemService: AppMensagemService
  ) {
    this.gc = new CommunicationManager(this.http, this.auth, this.mensagemService);
  }

  getAllByStatus(status: enumTabelaStatusOrcamento, exibeCarregando: boolean | false): Observable<OrcamentoModel[]> {
    return this.gc.get(this.api, `${this.version}${this.controller}/getAllByStatus/${status}`, exibeCarregando);
  }
  getAllByFiltroEData(filtro: FiltroPesquisaOrcamentoModel, exibeCarregando: boolean | false): Observable<OrcamentoModel[]> {
    return this.gc.post(this.api, `${this.version}${this.controller}/getAllByFiltroEData`, filtro, exibeCarregando);
  }

  getSimulacoesPagamentos(idOrcamento: number, exibeCarregando: boolean | false): Observable<ValoresOrcamentoSimulacaoPagamentoModel[]> {
    return this.gc.get(this.api, `${this.version}${this.controller}/getSimulacoesPagamentos/${idOrcamento}`, exibeCarregando);
  }

  getFormasPagamentosByIdSimulacaoPagamento(idOrcamentoSimulacaoPagamento: number, exibeCarregando: boolean | false): Observable<FormaPagamentoModel[]> {
    return this.gc.get(this.api, `${this.version}${this.controller}/getFormasPagamentosByIdSimulacaoPagamento/${idOrcamentoSimulacaoPagamento}`, exibeCarregando);
  }

  getById(idOrcamento, idTabelaPreco: number, exibeCarregando: boolean | false): Observable<OrcamentoModel> {
    return this.gc.get(this.api, `${this.version}${this.controller}/${idOrcamento}/${idTabelaPreco}`, exibeCarregando);
  }

  post(inputDados: OrcamentoModel, exibeCarregando: boolean) {
    return this.gc.post(this.api, `${this.version}${this.controller}`, inputDados, exibeCarregando);
  }

  removerItemOrcamentoProduto(inputDados: OrcamentoProdutoModel, exibeCarregando: boolean) {
    return this.gc.post(this.api, `${this.version}${this.controller}/removerItemOrcamentoProduto`, inputDados, exibeCarregando);
  }

  removerOrcamento(inputDados: OrcamentoModel, exibeCarregando: boolean) {
    return this.gc.post(this.api, `${this.version}${this.controller}/removerOrcamento`, inputDados, exibeCarregando);
  }


}
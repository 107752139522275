import { enumNotaFiscalOrigem } from "src/app/enum/enumNotaFiscalOrigem";
import { enumSimNao } from "src/app/enum/enumSimNao";
import { NotaFiscalDadosCobrancaModelInstance } from "../NotaFiscalDadosCobranca/NotaFiscalDadosCobrancaModelInstance";
import { NotaFiscalDestinatarioModelInstance } from "../NotaFiscalDestinatario/NotaFiscalDestinatarioModelInstance";
import { notaFiscalEmitenteModelInstance } from "../NotaFiscalEmitente/notaFiscalEmitenteModelInstance";
import { NotaFiscalIdentModelInstance } from "../NotaFiscalIdent/NotaFiscalIdentModelInstance";
import { NotaFiscalInfAdModelInstance } from "../NotaFiscalInfAd/NotaFiscalInfAdModelInstance";
import { NotaFiscalTotalIcmModelInstance } from "../NotaFiscalTotalIcm/notaFiscalTotalModelInstance";
import { NotaFiscalTotalIssqnModelInstance } from "../NotaFiscalTotalIssqn/NotaFiscalTotalIssqnModelInstance";
import { NotaFiscalTotalRetencaoTributoModelInstance } from "../NotaFiscalTotalRetencaoTributo/notaFiscalTotalRetencaoTributoModelInstance";
import { NotaFiscalTransporteModelInstance } from "../NotaFiscalTransporte/NotaFiscalTransporteModelInstance";
import { NotaFiscalTransReboqueModelInstance } from "../NotaFiscalTransReboque/NotaFiscalTransReboqueModelInstance";
import { NotaFiscalTransVolModelInstance } from "../NotaFiscalTransVol/NotaFiscalTransVolModelInstance";
import { NotaFiscalModel } from "./notaFiscalModel";



export class NotaFiscalModelInstance {
    public static get(): NotaFiscalModel {
        return {
            idNotaFiscal: 0,
            estornouEstoqueContabil: enumSimNao.Nao,
            estornouEstoqueVirtual: enumSimNao.Nao,
            idNotaFiscalServicoWeb: null,
            id: '',
            versao: '4.0.0',
            signature: '',
            origem: enumNotaFiscalOrigem.Venda,
            enviarEmailAutomaticoCliente: 'N',
            status: '',
            dataRegistro: null,
            dataAlteracao: null,
            mensagem: '',
            valor: 0,
            urlPdf: '',
            urlXml: '',
            statusEnvio: '',
            numero: '',
            serie: '',
            idExterno: '',
            cpfAlteracao: '',
            cpfRegistro: '',
            protocoloEnvio: '',
            idLoja: 0,
            idVenda: 0,
            dataAutorizacao: null,
            dataEmissao: null,
            tipo: '',
            numeroPdv: '',
            protocoloAutorizacao: '',
            qrCodeCupomFiscal: '',
            valorTotalImposto: null,
            idNotaFiscalServicoWebNavigation: null!,
            notaFiscalDadosCobranca: null!,
            notaFiscalDestinatario: null!,
            notaFiscalEmitente: null!,
            notaFiscalIdent: null!,
            notaFiscalIdentLocalEntrega: null!,
            notaFiscalInfAd: null!,
            notaFiscalInfComercioExterior: null!,
            notaFiscalInfCompra: null!,
            notaFiscalInfResTecnico: null!,
            notaFiscalIntermediadorTransacao: null!,
            notaFiscalTotalIcm: null!,
            notaFiscalTransporte: null!,
            compras: null!,
            estoqueMovimentacaos: null!,
            idVendaNavigation: null!,
            notaFiscalAutorizacaoObtemXmls: null!,
            notaFiscalCancelamentos: null!,
            notaFiscalCorrecaos: null!,
            notaFiscalEnvioEmails: null!,
            notaFiscalEventos: null!,
            notaFiscalIdentLocalRetiradum: null!,
            notaFiscalInfPagamentos: null!,
            notaFiscalInfSuplementare: null!,
            notaFiscalIssqn: null!,
            notaFiscalManifestacaos: null!,
            notaFiscalProdServs: null!,
            vendaEntregas: null!,
            notaFiscalAnexoArquivos: null!,
            codigoCsc: '',
            componenteEmissao: '',
            movimentouEstoqueContabil: enumSimNao.Nao,
            movimentouEstoqueVirtual: enumSimNao.Nao,
            informacaoAdicionalInicial: '',
            estoqueTransferenciaEntreLojas: null!
        };
    }

    public static getFullInstance(): NotaFiscalModel {
        let retorno = this.get();
        retorno.notaFiscalIdent = NotaFiscalIdentModelInstance.get();
        retorno.notaFiscalDestinatario = NotaFiscalDestinatarioModelInstance.get();
        retorno.notaFiscalEmitente = notaFiscalEmitenteModelInstance.get();
        retorno.notaFiscalTransporte = NotaFiscalTransporteModelInstance.get();
        retorno.notaFiscalTransporte.notaFiscalTransReboques = NotaFiscalTransReboqueModelInstance.getArray()
        retorno.notaFiscalTransporte.notaFiscalTransVols = NotaFiscalTransVolModelInstance.getArray();
        retorno.notaFiscalTotalIcm = NotaFiscalTotalIcmModelInstance.get();
        retorno.notaFiscalTotalIcm.notaFiscalTotalRetencaoTributo = NotaFiscalTotalRetencaoTributoModelInstance.get();
        retorno.notaFiscalTotalIcm.notaFiscalTotalIssqn = NotaFiscalTotalIssqnModelInstance.get();
        retorno.notaFiscalInfPagamentos = [];
        retorno.notaFiscalDadosCobranca = NotaFiscalDadosCobrancaModelInstance.get();
        retorno.notaFiscalInfAd = NotaFiscalInfAdModelInstance.get();
        //retorno.notaFiscalInfCompra = NotaFiscalInfCompraModelInstance.get();
        return retorno;
    }
}

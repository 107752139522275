import { enumSimNao } from "src/app/enum/enumSimNao";
import { VendumModel } from "./vendumModel";

export class VendaModelInstance {
    public static get(): VendumModel {
        return {
            idVenda: 0,
            cpfCnpj: '',
            idOrdemServico: 0,
            idOrcamento: 0,
            idTabelaStatusVenda: 0,
            idLoja: 0,
            idPdv: 0,
            idParametrizacaoVenda: 0,
            idMotivoDevolucao: 0,
            idMotivoCancelamento: 0,
            idAtividadeAgendada: 0,
            documentoReferenciadoNf: '',
            valorTotal: 0,
            valorDescontoCondicaoPagamento: 0,
            valorDescontoVendedor: 0,
            valorDespesaCondicaoPagamento: 0,
            valorJurosCondicaoPagamento: 0,
            valorTotalDescontoVendaProduto: 0,
            valorTotalFreteVendaProduto: 0,
            valorTotalOutrasDespesasVendaProduto: 0,
            valorTotalProdutoServicoVendaProduto: 0,
            lancouContasAPagReceber: '',
            emitiuCte: '',
            emitiuNfce: '',
            emitiuNfe: '',
            emitiuNfse: '',
            idCaixa: null,
            movimentouEstoque: '',
            dataExecucao: new Date(),
            dataPrevistaEntrega: null,
            idNaturezaOperacao: null,
            informacaoDadosComplementares: '',
            observacaoInterna: '',
            dataRegistro: null!,
            cpfRegistro: '',
            dataAlteracao: null,
            cpfAlteracao: '',
            idCaixaNavigation: null!,
            idNaturezaOperacaoNavigation: null!,
            idMotivoCancelamentoNavigation: null!,
            idMotivoDevolucaoNavigation: null!,
            idOrcamentoNavigation: null!,
            idOrdemServicoNavigation: null!,
            idParametrizacaoVendaNavigation: null!,
            idPdvNavigation: null!,
            idTabelaStatusVendaNavigation: null!,
            comandaVenda: null!,
            estoqueEntradaSaida: null!,
            localEntregaVenda: null!,
            vendaControlePerda: null!,
            estoqueMovimentacaos: null!,
            formaPagamentos: [],
            idContrato: 0,
            idContratoNavigation: null!,
            notaFiscals: null!,
            cpfCnpjNavigation: null!,
            preCpfCnpjNavigation: null!,
            preCpfCnpj: '',
            proCpfCnpjNavigation: null!,
            proCpfCnpj: '',
            vendaProdutos: null!,
            vendaStatusAlterados: null!,
            vendaEntregas: null!,
            condicionalVenda: null!,
            vendaVales: null!,
            valorCustoTotal: 0,
            valorTotalSeguroVendaProduto: 0,
            tipoVenda: '',
            enviouMsgWhatsappPosVenda: '',
            pedidoVenda: null!,
            valorTotalEmDinheiro: 0,
            valorTotalTroco: 0,
            observacaoEntrega: '',
            idTabelaPreco: 0,
            cartaoFidelidadeTransacaos: [],
            estoqueTransferenciaEntreLojas: null!,
            transfEntreLoja: enumSimNao.Nao,
            vendaAnexos: [],
            compraProdutoPacote: null!,
            feedbackResposta: [],
            vendaOrdemServicos: [],
            gerouBoleto: enumSimNao.Nao,
            importouComanda: enumSimNao.Nao,
            importouOrcamento: enumSimNao.Nao,
            importouOrdemServico: enumSimNao.Nao,
            importouPedidoVenda: enumSimNao.Nao
        };
    }

    public static getArray(): VendumModel[] {
        return [this.get()];
    };
}
